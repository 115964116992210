import ComingSimple from '../Components/Pages/ComingSoon/ComingSimple';
import Register from '../Components/Pages/Auth/Register';
import ForgetPwd from '../Components/Pages/Auth/ForgetPwd';
import ConfirmationMail from '../Components/Pages/Auth/ConfirmationMail';
import HomePage from '../HomePage';

import ErrorPage1 from '../Components/Pages/ErrorPages/ErrorPage400';
import ErrorPage2 from '../Components/Pages/ErrorPages/ErrorPage401';
import ErrorPage3 from '../Components/Pages/ErrorPages/ErrorPage403';
import ErrorPage4 from '../Components/Pages/ErrorPages/ErrorPage404'; 

import ComingBgImg from '../Components/Pages/ComingSoon/ComingBgImg';
import ComingBgVideo from '../Components/Pages/ComingSoon/ComingBgVideo';
import Error500 from '../Components/Pages/ErrorPages/ErrorPage500';
import Error503 from '../Components/Pages/ErrorPages/ErrorPage503'; 
import RecuperacaoSenha from '../Components/Pages/Auth/RecuperacaoSenha';
import PoliticaPrivacidadePage from '../Components/Pages/Public/PoliticaPrivacidade';
import TermoServicoPage from '../Components/Pages/Public/TermoServicos';
import LoginTab from '../Auth';
import WebhookGit from '../Components/Pages/Auth/Webhook';

export const authRoutes = [


 
  { path: `${process.env.PUBLIC_URL}/home`, Component: <HomePage /> },
  { path: `${process.env.PUBLIC_URL}/login`, Component: <LoginTab /> },
  { path: `${process.env.PUBLIC_URL}/register`, Component: <Register /> }, 
  { path: `${process.env.PUBLIC_URL}/forget-pwd`, Component: <ForgetPwd /> },
  { path: `${process.env.PUBLIC_URL}/webhook`, Component: <WebhookGit /> },
  { path: `${process.env.PUBLIC_URL}/public/mailconfirmacao`, Component: <ConfirmationMail /> },
  { path: `${process.env.PUBLIC_URL}/public/recuperacaoSenha`, Component: <RecuperacaoSenha /> },
  { path: `${process.env.PUBLIC_URL}/public/politicaPrivacidade`, Component: <PoliticaPrivacidadePage /> },
  { path: `${process.env.PUBLIC_URL}/public/termoServicos`, Component: <TermoServicoPage /> },
  
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`, Component: <ComingSimple /> },
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-img`, Component: <ComingBgImg /> },
  { path: `${process.env.PUBLIC_URL}/pages/comingsoon/coming-bg-video`, Component: <ComingBgVideo /> },

  //Error
  { path: `${process.env.PUBLIC_URL}/pages/errors/error400`, Component: <ErrorPage1 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error401`, Component: <ErrorPage2 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error403`, Component: <ErrorPage3 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error404`, Component: <ErrorPage4 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error500`, Component: <Error500 /> },
  { path: `${process.env.PUBLIC_URL}/pages/errors/error503`, Component: <Error503 /> },
];
