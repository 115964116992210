import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumbs, H4, LI, UL } from "../../../../../AbstractElements";
import 'react-date-range/dist/styles.css'; // Estilos principais
import 'react-date-range/dist/theme/default.css'; // Tema padrão
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Image } from "react-feather";
import DownloadService from "../../../../../Services/utils/download.service";
import EnviarMensagemCliente from "../enviarmensagem";
import PontuarModal from "./PontuarModal";
import { Link } from "react-router-dom";
import { point } from "leaflet";
import { FiGift, FiStar } from "react-icons/fi"; 
import ResgatarModal from "./ResgatarModal";

const CrmClienteDashboard = () => {
  const cliente = JSON.parse(localStorage.getItem('clienteId'));

  const picture = cliente.client.picture ? DownloadService.getBase64PictureObject(cliente.client.picture) : '';
  const [modalPontuar, setModalPontuar] = useState(false);
  const [modalResgatar, setModalResgatar] = useState(false);
 
  const togglePontuar = useCallback(() => {
    setModalPontuar(!modalPontuar);
  }, [modalPontuar]);

  const toggleResgatar = useCallback(() => {
    setModalResgatar(!modalResgatar);
  }, [modalResgatar]);

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Breadcrumbs mainTitle={'Cliente : ' + cliente.client.name} parent='CRM' title='Pontuar / Resgatar' />
      <Container fluid={true} className='general-widget'>
        <Row>
          <Col md='6' className='box-col-6'>
            <Card className={`course-box }`}>
              <CardBody>
                <div className='course-widget'>

                </div>
              </CardBody></Card>
          </Col>

          <Col md='3' className='box-col-6'>
            <Card className={`btn btn-success course-box`} onClick={togglePontuar} style={{ cursor: 'pointer' }}>
              <CardBody>
                <div className='course-widget'>
                  <div className={`course-icon warning}`}>
                    <FiStar className='fill-icon' /> {/* Ícone de estrela */}
                  </div>
                  <div>
                    <H4 attrH4={{ className: 'mb-0' }}> Pontuar</H4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md='3' className='box-col-6'>
            <Card className={`btn btn-danger course-box`} onClick={toggleResgatar} style={{ cursor: 'pointer' }}>
              <CardBody>
                <div className='course-widget'>
                  <div className={`course-icon warning}`}>
                    <FiGift className='fill-icon' /> {/* Ícone de presente */}
                  </div>
                  <div>
                    <H4 attrH4={{ className: 'mb-0' }}> Resgatar</H4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row></Container>
        <PontuarModal modal={modalPontuar} setModalPontuar={setModalPontuar} client={cliente} toggle={togglePontuar} />
        <ResgatarModal modal={modalResgatar} toggle={toggleResgatar} />
    </>
  );
};

export default CrmClienteDashboard; 