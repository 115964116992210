import React, { Fragment , useContext, useRef , useState , useEffect } from 'react';
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H4, H6, P, Image, Btn } from '../../../../AbstractElements'; 
import userService from '../../../../Services/administrativo/user.service';
import downloadService from '../../../../Services/utils/download.service';
import { Link } from 'react-router-dom';
import CustomizerContext from '../../../../_helper/Customizer';
import { Bio, Save } from '../../../../Constant';

const MyProfileEdit = (props) => {
  const { layoutURL } = useContext(CustomizerContext);
  const name = JSON.parse(localStorage.getItem('name'));
  const [mail, setMail] = useState(props.user.mail);
  const userId = props.user.id; 
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState('');


  useEffect(() => {
   
    const fetchImage = async () => {
      try {
        const image = await downloadService.getUrlFoto(); 
        setImageUrl(image);
      } catch (error) {
        console.error('Erro ao baixar a imagem:', error);
      }
    };
    fetchImage();
  }, []); 
 

 
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    if (event.target.files.length === 0) return;

    try {
      const formData = new FormData();
      formData.append('file', event.target.files[0]);

      const response = await userService.uploadPhoto(userId, formData); 
      const image = await downloadService.getImagem(response.data);
      const imageBase64 = `data:image/${image.data.format};base64,${image.data.base64}`;
      setImageUrl(imageBase64); 

      //setUploaded(true);
    } catch (error) {
      console.error('Erro ao enviar o arquivo:', error);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <H4 attrH4={{ className: 'card-title mb-0' }}>My Profile</H4>
          <div className='card-options'>
            <a className='card-options-collapse' href='#javascript'>
              <i className='fe fe-chevron-up'></i>
            </a>
            <a className='card-options-remove' href='#javascript'>
              <i className='fe fe-x'></i>
            </a>
          </div>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className='mb-2'>
              <div className='profile-title'>
                <div className='media'>
                  <Image attrImage={{ className: 'img-70 img-fluid m-r-5 rounded-circle b-r-10 m-0 step1', alt: '', src: `${imageUrl}` }} />
                  <i className='icofont icofont-pencil-alt-5'   onClick={handleIconClick}>
                    <input type="file" style={{ display: 'none' }}  ref={fileInputRef} onChange={(e) => handleSubmit(e)} accept="image/*"  />
                  </i>
                  <div className='media-body'>
                    <Link to={`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`}>
                      <H5 attrH5={{ className: 'mb-1' }}>{name}</H5>
                    </Link>                   
                  </div> 
                </div>
              </div>
            </Row>
            
            <FormGroup className='mb-3'>
              <Label className='form-label'>EmailAddress</Label>
              <Input className='form-control'  onChange={ (e) => setMail(e.target.value)} value={mail} />
            </FormGroup>
            <FormGroup className='mb-3'>
              <Label className='form-label'>Password</Label>
              <Input className='form-control' type='password' defaultValue='password' />
            </FormGroup>
            
            <div className='form-footer'>
              <Btn attrBtn={{ className: 'btn-block', color: 'primary' }}>{Save}</Btn>
            </div>






          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default MyProfileEdit;
