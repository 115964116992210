import React, { useState } from 'react';
import { Card, CardBody, CardFooter, Col, Container, Form, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import { useNavigate } from 'react-router';
import clientService from '../../../../../Services/administrativo/cliente.service';
import { Breadcrumbs } from '../../../../../AbstractElements';
import SweetAlert from 'sweetalert2';
import { FiXCircle, FiSave, FiAlertCircle } from 'react-icons/fi';


const NovoClienteForm = () => {
    const celular = localStorage.getItem('novoCliente');
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [emailError, setEmailError] = useState('');
    const [dataNascimentoError, setDataNascimentoError] = useState('');
    const [nomeError, setNomeError] = useState('');
    const history = useNavigate();
    const company = JSON.parse(localStorage.getItem('company'));

    var request = {
        cellphone: celular,
        name: nome,
        mail: email,
        dateOfBirth: dataNascimento,
    };

    // Função para validar o email usando regex
    const validarEmail = (email) => {
        if (email === '') return true;
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    // Função para validar a data de nascimento (deve ser no passado)
    const validarDataNascimento = (data) => {
        const hoje = new Date();
        const dataNascimento = new Date(data);
        return dataNascimento < hoje; // A data deve ser anterior a hoje
    };

    const validarNome = (nome) => {
        // Verifica se contém números
        const regexNumeros = /\d/;
        if (regexNumeros.test(nome)) {
            return false; // Contém números, então é inválido
        }

        // Divide o nome em palavras
        const palavras = nome.trim().split(/\s+/);

        // Verifica se há pelo menos duas palavras
        if (palavras.length < 2) {
            return false; // Menos de duas palavras
        }

        // Verifica se cada palavra tem pelo menos duas letras
        for (const palavra of palavras) {
            if (palavra.length < 2) {
                return false; // Palavra com menos de duas letras
            }
        }

        return true; // Nome válido
    };

    const salvar = async () => {
        // Verifica se o email é válido
        let temErro = false;
        if (!validarEmail(email)) {
            setEmailError('Email inválido');
            temErro = true;
        } else {
            setEmailError('');
        }

        // Verifica se a data de nascimento é válida
        if (dataNascimento === '') {
            setDataNascimentoError('Data de nascimento é obrigatória');
            temErro = true;
        } else if (!validarDataNascimento(dataNascimento)) {
            setDataNascimentoError('A data de nascimento deve ser anterior à data atual');
            temErro = true;
        } else {
            setDataNascimentoError('');
        }


        // Verifica se a data de nascimento é válida
        if (!validarNome(nome)) {
            setNomeError('O campo nome é obrigatório');
            temErro = true;
        } else {
            setNomeError('');
        }

        if (temErro) return;


        // Se as validações passarem, salva o cliente
        clientService.save(request, company.id).then((response) => {
            SweetAlert.fire({
                title: 'Cliente cadastrado com sucesso!',
                text: 'Deseja realizar o cadastro?',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'OK',
                reverseButtons: true,
            })

            localStorage.setItem('clienteId', JSON.stringify(response.data));
            history('/crm/clientes/dashboard');
        }).catch((error) => {
            SweetAlert.fire({ title: 'Desculpe', text: 'Ocorreu um erro ao salvar o cliente', icon: 'danger', });
        });
    };

    const cancelar = () => {
        history(-1);
    };

    function formatarCelular(telefone) {
        const ddd = telefone.slice(0, 2); // Pega os dois primeiros números (DDD)
        const parte1 = telefone.slice(2, 7); // Pega os próximos cinco números (parte inicial)
        const parte2 = telefone.slice(7); // Pega os últimos quatro números (parte final)
        return `(${ddd}) ${parte1}-${parte2}`;
    }

    return (
        <> <Breadcrumbs mainTitle={'Cadastrando novo cliente'} parent='CRM' title='Pontuar / Resgatar' />
            <Container fluid={true}>
                <Card className='height-equal'>
                    <HeaderCard title={'Informe os dados do cliente'} />
                    <CardBody>
                        <Form className='theme-form mega-form'>
                            <Row>
                                <Col md={4} className="d-flex justify-content-center align-items-center">
                                    <img
                                        src="https://via.placeholder.com/150" // Substitua pela URL da imagem que você escolher
                                        alt="Cadastro de Usuário"
                                        style={{ width: '100%', maxWidth: '300px', height: 'auto' }}
                                    />
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label className="col-form-label">Telefone:</Label>
                                                <Input className="form-control btn-square disabled" type="text" disabled value={formatarCelular(celular)} />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="col-form-label">Data de Nascimento:</Label>
                                                <Input className="form-control btn-square" type="date" placeholder="dd/mm/aaaa" onChange={(e) => setDataNascimento(e.target.value)} value={dataNascimento} />
                                                {dataNascimentoError && <p style={{ color: 'red' }}>{dataNascimentoError}</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Label className="col-form-label">Nome</Label>
                                        <InputGroup>
                                            <Input className={`form-control btn-square ${nomeError ? 'is-invalid' : ''}`} type="text" onChange={(e) => setNome(e.target.value)} value={nome} />
                                            {nomeError && (
                                                <InputGroupText className="bg-danger text-white">
                                                    <FiAlertCircle />
                                                </InputGroupText>
                                            )}
                                        </InputGroup>
                                        {nomeError && <p style={{ color: 'red' }}>{nomeError}</p>}
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="col-form-label">Email</Label>
                                        <InputGroup>
                                            <Input className={`form-control btn-square ${emailError ? 'is-invalid' : ''}`} type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
                                            {emailError && (
                                                <InputGroupText className="bg-danger text-white">
                                                    <FiAlertCircle />
                                                </InputGroupText>
                                            )}
                                        </InputGroup>


                                        {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col>
                                <button type="button" className="btn btn-danger btn-lg w-100 input-group-text" onClick={cancelar}>
                                    <FiXCircle style={{ marginRight: '5px' }} />
                                    Cancelar
                                </button>
                            </Col>

                            <Col>
                                <button type="button" className="btn btn-success btn-lg  w-100 input-group-text" onClick={salvar}>
                                    <FiSave style={{ marginRight: '5px' }} />
                                    Salvar e continuar
                                </button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Container>
        </>
    );
};

export default NovoClienteForm;
