import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/authentication/importacao";

 class ImportacaoService   {
  
  getAll(request) {    
    return axios
      .post(API_URL + '/filter' ,  request  , { headers: authHeader() });
  }
 
  getUrlImportacaoCliente(companyId, userId) {
    return API_URL + '/importarCliente/' + companyId + '/' + userId;
  }
}  
 
export default  new ImportacaoService();    
 