import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/authentication/companyClient";

 class ClientService   {
  


  find(request, companyId) {    
    return axios
      .get(API_URL+ '/findByCodeQr/'+request + '/'+companyId,  { headers: authHeader() });
  }

  findById(userId) {    
    return axios
      .get(API_URL+ '/'+userId,  { headers: authHeader() });
  }

  novaListaAll(companyId, request, page, size, sortColumn, sortDirection) {    
    return axios
      .post(API_URL+ '/listarClientes/'+companyId + "?page="+ page + "&size=" + size + '&sort = ' + `${sortColumn},${sortDirection}`, request , { headers: authHeader() });
  }

  pontuar(request) {    
    return axios
      .post(API_URL+ '/pontuar', request,  { headers: authHeader() });
  }

  resgatar(request) {    
    return axios
      .post(API_URL+ '/resgatar', request,  { headers: authHeader() });
  }

  extractPoints(clienteId) {    
    return axios
      .get(API_URL+ '/extractPoints/'+clienteId,  { headers: authHeader() });
  }

  save(request, companyId) {    
    return axios
      .post(API_URL+ '/'+companyId, request, { headers: authHeader() });
  } 

  getFormaCadastro(formCadastro) {    
    if (formCadastro === 'VIA_APP') return 'Via aplicativo';
    else if (formCadastro === 'IMPORTACAO') return 'Via importação';
    else if (formCadastro === 'VIA_SISTEMA') return 'Via Sistema';
    else return formCadastro;
  } 
 

  enviarEmail(companyId, request) {
    return axios
      .post(API_URL+ '/enviarEmail/'+companyId, request, { headers: authHeader() });
  }

  enviarWhatsapp(companyId, request) {
    return axios
      .post(API_URL+ '/enviarWhatsapp/'+companyId, request, { headers: authHeader() });
  }

  enviarPushNotificationIndividual(companyId, request) {
    return axios
      .post(API_URL+ '/enviarPushNotificationIndividual/'+companyId, request, { headers: authHeader() });
  }

  enviarPushNotificationGeral(companyId, request) {
    return axios
      .post(API_URL+ '/enviarPushNotificationGeral/'+companyId, request, { headers: authHeader() });
  }

  enviaSms(companyId, request) {
    return axios
      .post(API_URL+ '/enviarSms/'+companyId, request, { headers: authHeader() });
  }

  
 
}  
 
export default  new ClientService();    
 