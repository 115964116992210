import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig';  

const API_URL = config.data.API_URL+"/authentication/company";

 const  CrmCompanyService =  {
  
  getId(companyId) {    
    return axios
      .get(API_URL+ '/'+companyId ,  { headers: authHeader() });
  }
 
 
  
}  

export default   CrmCompanyService; 