export const MENUITEMS = [
 
  {
    menutitle: 'CRM',
    menucontent: 'CRM',
    role: "can_view_crm_module",
    module: "CRM",
    Items: [
      { 
        path: `${process.env.PUBLIC_URL}/crm/dashboard`, 
        icon: "home", 
        title: "Início", 
        type: "link" ,  
        role: 'can_view_crm_dashboard' , 
        active : true 
      }, 
      { 
        path: `${process.env.PUBLIC_URL}/crm/pontuarresgatar`, 
        icon: "project", 
        title: "Pontuar / Resgatar", 
        type: "link" ,  
        role: 'can_view_crm_dashboard' , 
        active : true 
      }, 
      { 
        path: `${process.env.PUBLIC_URL}/crm/recuperarcliente`, 
        icon: "learning", 
        title: "Recuperar Cliente", 
        type: "link" ,  
        role: 'can_view_crm_dashboard' , 
        active : true 
      }, 
      {
        title: 'Clientes',
        icon: 'bookmark',
        type: 'sub',
        role: "can_view_crm_report",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/clientes/listaClientes`,
            title: 'Lista de Clientes',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Métricas',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Transações',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/clientes/segmentacoes`,
            title: 'Segmentações',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Consumo Mensal',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          
        ]
      },
      {
        title: 'Minha loja',
        icon: 'form',
        type: 'sub',
        role: "can_view_crm_report",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/minhaloja/informacoesbasica`,
            title: 'Informações Básicas',
            type: 'link',
            role: 'can_view_crm_birthday'
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/minhaloja/parametrizacao`,
            title: 'Parametrizações',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
        
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/minhaloja/identidadevisual`,
            title: 'Identidade Visual',
            type: 'link',
            role: 'can_view_crm_birthday'
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/minhaloja/identidadevisual`,
            title: 'Aplicativo',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
        
          
        ]
      },
      {
        title: 'Métricas',
        icon: 'charts',
        type: 'sub',
        role: "can_view_crm_report",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Gráficos',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'NPS',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Desempenho',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Repetição de compras',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          
        ]
      },
      
      
      {
        title: 'Atendentes',
        icon: 'user',
        type: 'sub',
        role: "can_view_crm_report",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Desempenho',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Metas',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Gerenciar',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          
        ]
      },
   
      

      


      {
        title: 'Premiações',
        icon: 'icons',
        type: 'sub',
        role: "can_view_crm_report",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Gerenciar',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Relatórios',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/crm/report/birthdays`,
            title: 'Desempenho',
            type: 'link',
            role: 'can_view_crm_birthday'
          },
          
        ]
      },
   
   
    ],
  },
  
  {
    menutitle: 'ADMINISTRATIVO',
    menucontent: 'ADMINISTRATIVO',
    role: "can_view_administrator_module",
    module: "ADMINISTRATIVO",
    Items: [
      { path: `${process.env.PUBLIC_URL}/administrativo/dashboard`, icon: "home", title: "Dashboard", type: "link" ,  role: 'can_view_admnistrativo_dashboard' },
      {
        title: 'Cadastros',
        icon: 'support-tickets',
        type: 'sub',
        role: "can_view_administrator_registers",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/company`,
            title: 'Empresas',
            type: 'link',
            role: 'can_view_company'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/person`,
            title: 'Pessoa',
            type: 'link',
            role: 'can_view_person'
          },
          ,
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/branch`,
            title: 'Filiais',
            type: 'link',
            role: 'can_view_branch'
          },
        ]
      },
  
      {
        title: 'Settings',
        icon: 'support-tickets',
        type: 'sub',
        role: "can_view_administrator_settings",
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/integration`,
            title: 'Integrações',
            type: 'link',
            role: 'can_view_city'
          },

          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/country`,
            title: 'Country',
            type: 'link',
            role: 'can_view_country'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/state`,
            title: 'State',
            type: 'link',
            role: 'can_view_state'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/city`,
            title: 'City',
            type: 'link',
            role: 'can_view_city'
          },
        ],
      },
      
      {
        title: 'Controle de Acesso',
        icon: 'user',
        type: 'sub',
        role: "can_view_administrator_controle_acesso",
        children: [

         
     
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/user`,
            title: 'Users',
            type: 'link',
            role: 'can_view_role'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/role`,
            title: 'Roles',
            type: 'link',
            role: 'can_view_role'
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/administrativo/perfil`,
            title: 'Perfis',
            type: 'link',
            role: 'can_view_perfil'
          },
        ],
      },
      { path: `${process.env.PUBLIC_URL}/administrativo/file-manager`, icon: "file", title: "File-Manager", type: "link", role: 'can_view_file_manager' },
      {
        title: "Blog",
        icon: "blog",
        type: "sub",
        active: false,
        role: "can_view_administrator_blog",
        children: [
          { path: `${process.env.PUBLIC_URL}/blog/blogdetails`, title: "BlogDetails", type: "link", role: 'can_view_dashobard_default' },
          { path: `${process.env.PUBLIC_URL}/blog/blogsingle`, title: "BlogSingle", type: "link", role: 'can_view_dashobard_default' },
          { path: `${process.env.PUBLIC_URL}/blog/blogpost`, title: "BlogPost", type: "link", role: 'can_view_dashobard_default' },
        ],
      },
      { path: `${process.env.PUBLIC_URL}/faq`, icon: "faq", title: "FAQ", type: "link" ,  role: 'can_view_crm_dashboard' , active : true }, 
      
    ],
  },
  
];
