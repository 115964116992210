import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs, Btn } from '../../../AbstractElements';
import notificationService from '../../../Services/utils/notificacao.service';
import { Edit2, Trash } from 'react-feather';
import NotificacaoListData from './NotificacaoListData';
const style2 = { width: 60, fontSize: 14, padding: 4 };


const NotificationPage = () => {
    const [notifications, setNofificacoes] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        getNotificacoes();
    }, [])

    const getNotificacoes = async () => {
        try {
            const response = await notificationService.getUserId(user.id);

            var notificacaoList = await response.data;

            const transformedItems = notificacaoList.map((notificacao) => ({
                ...notificacao,

                action: (
                    <div> {
                        notificacao.read ? '' :

                            <Btn attrBtn={{ style: style2, color: 'warning', className: 'btn btn-xs', type: 'button', onClick: (e) => { btnLer(notificacao)} }}>
                                <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' />
                            </Btn>
                    } </div>
                )}
            )
            );
            setNofificacoes(transformedItems);
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const btnLer = (notificacao) => {
        
      }


    return (
        <>
            <Breadcrumbs mainTitle='Notificações' parent="/" title="Notificações" />
            <Container fluid={true}>
                <NotificacaoListData loading={loading} error={error} list={notifications} />

            </Container>
        </>
    );
};
export default NotificationPage;