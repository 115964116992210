import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 

const API_URL = config.data.API_URL+"/authentication/segmentacao";

 class SegmentacaoService   {
  


  findByCompanyId(companyId) {    
    return axios
      .get(API_URL+ '/byCompanyId/'+companyId,  { headers: authHeader() });
  } 

  getId(segmentacaoId) {    
    return axios
      .get(API_URL+ '/' + segmentacaoId,  { headers: authHeader() });
  }


  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter?page='+page+'&size='+size, request, { headers: authHeader() });
  }

  findByCpfCnpj(cpfCnpj) {
    return axios
    .get(API_URL + '/cpfCnpj/' + cpfCnpj, { headers: authHeader() });
 
  }
  
  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  }

  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  }

  update(request) {    
    return axios
      .put(API_URL+"/"+request.id, request, { headers: authHeader() });
  }

  delete(request) {    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  }
}  
 
export default  new SegmentacaoService();    
 