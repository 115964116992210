import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather';
import axios from 'axios';
import ReactQuill from 'react-quill';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';
import integrationService from '../../../../../Services/administrativo/integration.service';
import AlertError from '../../../../../CommonElements/AlertError';

const EnviarMensagemWhatsappForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState({});
    const [integracaoWhatsapp, setIntegracaoWhatsapp] = useState(null);
    
    const [loading, setLoading] = useState(true);
    const [enviandoWhatsapp, setEnviandoWhatsapp] = useState(false);

    
    useEffect(() => {
        setLoading(true);
        getDadosCliente();
         getDadosCliente();
       
        
    }, []);

    const getDadosCliente = async () => {
        return clienteService.findById(props.cliente)
            .then((response) => {
                setDadosUsuario(response.data);
                setLoading(false);
                getDadosIntegracaoWhatsapp();
                return response;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

     const getDadosIntegracaoWhatsapp = async () => {
        return integrationService.findByIntegrationWhatsapp(company.id)
            .then((response) => {
                setIntegracaoWhatsapp(response.data);
                setLoading(false);
                return response;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }; 

    const formataNumero = (numero) => {
      // Remove todos os caracteres que não são números
    let numeroLimpo = numero.replace(/\D/g, '');

    // Verifica se o número já começa com o código do país (55 para Brasil)
    if (numeroLimpo.length === 11) {
        // Adiciona o código do país (55)
        numeroLimpo = '55' + numeroLimpo;
    } else if (numeroLimpo.length === 13 && numeroLimpo.startsWith('55')) {
        // O número já está formatado corretamente com o código do país
        return numeroLimpo;
    }

    return numeroLimpo;
    }

    // Função para enviar o e-mail
    const enviarWhatsapp = async () => {
        setEnviandoWhatsapp(true);

        try {
            console.log(formataNumero(dadosUsuario.client.cellphone));
        const response = await clienteService.enviarWhatsapp(company.id, { 
            body: editorContent,
            companyId: company.id,
            integrationId: integracaoWhatsapp.id,
            to : formataNumero(dadosUsuario.client.cellphone)
        });
        setEnviandoWhatsapp(false);

        if (response.status === 201) {
            SweetAlert.fire({
                title: 'Whatsapp enviado com sucesso!',
                icon: 'success',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true,
            }).then((result) => {
               
                setEditorContent('');
            });
        } else {
            SweetAlert.fire({
                title: 'Erro ao enviar whatsapp',
                icon: 'danger',
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true,
            });

        }
    } catch (error) {
        console.error('Erro ao enviar whatsapp:', error);
        SweetAlert.fire({
            title: 'Erro ao enviar whatsapp',
            icon: 'danger',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            reverseButtons: true,
        });

        setEnviandoWhatsapp(false);

    }

     
    };

    return (loading ? 'Carregando dados' : integracaoWhatsapp ? 
        <>
            Enviar mensagem de whatsapp para: <H5>{dadosUsuario.client.cellphone}</H5>
            <Row>
                <Col>
                    <label htmlFor="emailDestinatario">Conteúdo</label>
                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={setEditorContent} // Atualiza o conteúdo do editor
                        style={{ height: '270px' }} // Define a altura do editor
                    />

                </Col>

            </Row>
            <br />
            <br />
            {
                enviandoWhatsapp ? 
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarWhatsapp}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </> :  <AlertError title={'Desculpe!'} message={'Não foi encontrada nenhuma integração de whatsappa para sua empresa!'}/> 
    );
}
export default EnviarMensagemWhatsappForm;
 