import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Codepen, LogIn, User, Users } from 'react-feather';
import man from '../../../assets/images/dashboard/profile.png';
import SweetAlert from 'sweetalert2';
import { LI, UL, Image, P } from '../../../AbstractElements';
import { LogOut, Profile } from '../../../Constant';
import userService from '../../../Services/administrativo/user.service';
import CustomizerContext from '../../../_helper/Customizer';
import { useTranslation } from "react-i18next";
import authService from '../../../Services/auth.service';
import downloadService from '../../../Services/utils/download.service';
import { toast } from 'react-toastify';
import AuthContext from '../../../_helper/Auth';
const UserHeader = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState(''); 
  const { user, authenticated} = useContext(AuthContext);
 
  const userId = JSON.parse(localStorage.getItem('userId'));
  const name = JSON.parse(localStorage.getItem('name'));
  
  const roles = JSON.parse(localStorage.getItem('roles')); 
  const { t } = useTranslation();
  const actualModule = localStorage.getItem("actualModule");
  const picture = localStorage.getItem("picture") === 'undefined' ? '' : localStorage.getItem("picture");
  const imageUrl = downloadService.getUrlFoto(); // `data:image/${picture.format};base64,${picture.base64}`; 

  const [moduloAtual, setModuloAtual] = useState(actualModule);

  useEffect(() => {
    setProfile(localStorage.getItem('profileURL') || man);
    //setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name);
  }, []);

  const Logout = () => {
    SweetAlert.fire({
      title: 'Deseja realmente sair do sistema?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        authService.logout();
        history(`${process.env.PUBLIC_URL}/login`);

      } else {
        toast("Que bom que mudou de ideia :) Seja vem vindo de volta", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    });



  };

  const changeModule = (module) => {
    localStorage.setItem('actualModule', module);
    setModuloAtual(module);
    userService.updateModuloAtual(userId, module);
    history(`${process.env.PUBLIC_URL}/${module.toLowerCase()}/dashboard`);
  }


  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <Image
          attrImage={{
            className: 'img-30 img-fluid m-r-20 rounded-circle',
            src: `${picture !== '' ? imageUrl : profile}`,
            alt: '',
          }}
        />
        <div className='media-body'>
          <span>{authenticated ? name : 'Indefinido'}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {moduloAtual} <i className='middle fa fa-angle-down'></i>
          </P>
        </div>
      </div>
      <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
        {
          (roles.indexOf('can_view_administrativo_module') > -1) ?
            <LI
              attrLI={{
                onClick: () => changeModule('ADMINISTRATIVO'),
              }}>
              <Codepen />
              <span>{t('Gestor')} </span>
            </LI> : ''
        }
        {
          /* (roles.indexOf('can_view_marketing_module') > -1) ?
              <LI
                attrLI={{
                  onClick: () => changeModule('MARKETING'),
                }}>
                <span>{t('Marketing')} </span>
              </LI> : '' */
        }
        {
          /*   (roles.indexOf('can_view_financeiro_module') > -1) ?
              <LI
                attrLI={{
                  onClick: () => changeModule('FINANCEIRO'),
                }}>
                <span>{t('Financeiro')} </span>
              </LI> : '' */
        }
        {
          /* (roles.indexOf('can_view_esporte_module') > -1) ?
             <LI
               attrLI={{
                 onClick: () => changeModule('ESPORTE'),
               }}>
               <span>{t('Esporte')} </span>
             </LI> : '' */
        }
        {
          /*    (roles.indexOf('can_view_projeto_module') > -1) ?
               <LI
                 attrLI={{
                   onClick: () => changeModule('PROJECT'),
                 }}>
                 <span>{t('Projeto')} </span>
               </LI> : ''*/
        }

        {/*
          (roles.indexOf('can_view_whatsapp_module') > -1) ?
            <LI
              attrLI={{
                onClick: () => changeModule('WHATSAPP'),
              }}>
              <span>{t('Whatsapp')} </span>
            </LI> : ''*/
        }

        {
          (roles.indexOf('can_view_crm_module') > -1) ?
            <LI
              attrLI={{
                onClick: () => changeModule('CRM'),
              }}>
              <Users />
              <span>{t('CRM')} </span>
            </LI> : ''
        }
        <LI
          attrLI={{
            onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/edit`),
          }}>
          <User />
          <span>{t(Profile)} </span>
        </LI>

        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
