
 
const  DateService =  {

  getDate(date) {   
    if (date == null ) return null;
    var valueTime = date.split('T');
    var values = valueTime[0].split('-');
    return date ? values[2] + '/' + values[1] + '/'  + values[0] : null; 
  },

  getDateTime(date) {   
    if (date == null ) return null;
    var valueTime = date.split('T');
    var values = valueTime[0].split('-');
    return date ? values[2] + '/' + values[1] + '/'  + values[0] + ' às ' + valueTime[1] : null; 
  },

  getTime(date) {   
    if (date == null ) return null;
    var valueTime = date.split('T'); 
    return date ? valueTime[1] : null; 
  }
,
  convertBadge(badges) {   
   return badges ?  <div>
      {badges.map((badge, index) => (
        <span key={index} style={{ backgroundColor:  badge.color ? badge.color : '' , color: badge.font ? badge.font : '',
          padding: '5px 10px',
          borderRadius: '12px',
          marginRight: '5px',
          display: 'inline-block',
          fontSize: '10px'}}>
          {badge.description ? badge.description : ''}
        </span>
      ))}
    </div> : ''
  }
 
 
 
}  

export default  DateService;
