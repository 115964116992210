import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Cancel, Collection, Contact, Description, EditBookmark, General, Group, Hometxt, MyBookmarks, Profile, Save, Title, WebUrl } from '../../../../../Constant';

import { Badges, Btn, H5, P } from '../../../../../AbstractElements';
import EnviarMensagemEmailForm from './EnviarMensagemEmailForm';
import EnviarMensagemWhatsappForm from './EnviarMensagemWhatsappForm';
import EnviarMensagemSmsForm from './EnviarMensagemSmsForm';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import EnviarMensagemPushNotificationForm from './EnviarMensagemPushNotificationForm';

const EnviarMensagemCliente = ({ toggle, modal, cliente }) => {
    const [BasicLineTab, setBasicLineTab] = useState('1');
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setUserId(cliente.userId);
        setLoading(false); 
    }, []);

   

    return (loading ? 'Carregando' :
        <>
            <Modal isOpen={modal} toggle={toggle} className='modal-body' centered={true} size='xl'>
                <ModalHeader toggle={toggle}><Badges> Enviando mensagem para {cliente.name}</Badges> </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col className='xl-100'>
                            <Nav className='border-tab d-flex' tabs>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={'w-100' + BasicLineTab === '1' ? ' active btn btn-succes' : ''} onClick={() => setBasicLineTab('1')}>
                                        <i className="icofont icofont-envelope"></i>
                                        {'Email'}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '2' ? 'active' : ''} onClick={() => setBasicLineTab('2')}>
                                        <i className="icofont icofont-brand-whatsapp"></i>
                                        {'Whatsapp'}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('3')}>
                                        <i className="icofont icofont-ui-messaging"></i>
                                        {'SMS'}
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-grow-1">
                                    <NavLink href='#' className={BasicLineTab === '3' ? 'active' : ''} onClick={() => setBasicLineTab('4')}>
                                        <i className="icofont icofont-ui-messaging"></i>
                                        {'Push Notification'}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={BasicLineTab}>
                                <TabPane className='fade show' tabId='1'>
                                    <EnviarMensagemEmailForm cliente={cliente.userId} />
                                </TabPane>
                                <TabPane tabId='2'>
                                    <EnviarMensagemWhatsappForm cliente={cliente.userId} />

                                </TabPane>
                                <TabPane tabId='3'>
                                    <EnviarMensagemSmsForm cliente={cliente.userId} />

                                </TabPane>
                                <TabPane tabId='4'>
                                    <EnviarMensagemPushNotificationForm cliente={cliente.userId} />

                                </TabPane>
                            </TabContent>

                        </Col>
                    </Row>

                </ModalBody>
            </Modal>
        </>
    );
};
export default EnviarMensagemCliente;