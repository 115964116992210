import axios from "axios";
import authHeader from "../auth-header";
import config from '../../Config/AppConfig'; 
import authHeaderFile from "../auth-header-file";

const API_URL = config.data.API_URL+"/authentication/company";

 class CompanyService    {
  
  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter?page='+page+'&size='+size, request, { headers: authHeader() });
  } 
 
  getAll() {    
    return axios
      .get(API_URL, { headers: authHeader() });
  } 

  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  } 

  update(request) {    
    return axios
      .put(API_URL+"/"+request.id, request, { headers: authHeader() });
  } 

  delete(request) {  
    
    return axios
      .delete(API_URL+"/"+request.id, { headers: authHeader() });
  } 

  uploadLogo(companyId, request ) {    
    return axios
      .post(API_URL + '/uploadLogo/'+companyId, request, { headers: authHeaderFile() });
  }

  atualizarValorParaPontuar(companyId, request ) {    
    return axios
      .post(API_URL + '/atualizarParametrizacao/'+companyId , request, { headers: authHeader() });
  }

  
}  

export default  new CompanyService(); 