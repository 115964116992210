import axios from "axios";
import config from '../../Config/AppConfig';
import authHeader from "../auth-header";


const API_URL = config.data.API_URL + "/authentication";
const DownloadService = {


  getSize(number) {
    if (number < 1024) return number + 'K';
    else {
      var totalMB = (number / 1024);
      var restoMB = (number % 1024);
      totalMB = totalMB + ',' + restoMB;
    } return totalMB + 'MB';
  },

  getUrlFoto() {
    const pictureLocal = localStorage.getItem('picture');
    if (pictureLocal === '') return null;
    if (pictureLocal === undefined) return null;
    if (pictureLocal === 'notfound.png') return null;

    const picture = JSON.parse(pictureLocal);



    return `data:image/${picture.format};base64,${picture.base64}`;
  },

  getBase64Picture(picture) {

    if (picture === undefined) return null;
    if (picture === 'notfound.png') return {};
    picture = JSON.parse(picture);

    return `data:image/${picture.format};base64,${picture.base64}`;
  },
  
  getBase64PictureObject(picture) {
    if (picture === undefined) return null;
    if (picture === 'notfound.png') return {}; 
    if (picture === null) return {};  
    return `data:image/${picture.format};base64,${picture.base64}`;
  },

  getImagem(codeLogo) {
    return axios.get(API_URL + '/download/image/' + codeLogo, { headers: authHeader() });
  },

  listFolders() {
    return axios.get(API_URL + '/download/listFolders', { headers: authHeader() });
  },

  listFiles() {
    return axios.get(API_URL + '/download/listFiles', { headers: authHeader() });
  }
}

export default DownloadService;
