import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather';
import axios from 'axios';
import ReactQuill from 'react-quill';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';

const EnviarMensagemSmsForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState({});
    
    const [loading, setLoading] = useState(true);
    const [enviandoSms, setEnviandoSms] = useState(false);

    
    useEffect(() => {
        setEnviandoSms(false);
        setLoading(true);
        getDadosCliente();
    }, []);

    const getDadosCliente = () => {
        return clienteService.findById(props.cliente)
            .then((response) => {
                setDadosUsuario(response.data);
                setLoading(false);
                return response;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };



    // Função para enviar o e-mail
    const enviarSms = async () => {
        setEnviandoSms(true);
        SweetAlert.fire({
            title: 'Mensagem SMS enviada com sucesso! (Em desenvolvimento)',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            reverseButtons: true,
        }).then((result) => {
            setEditorContent('');
        });    
        setEnviandoSms(false);
    };

    return (loading ? 'Carregando dados' :
        <>
            Enviar mensagem de SMS para: <H5>{dadosUsuario.client.cellphone}</H5>

            <Row>
                <Col>
                    <label htmlFor="emailDestinatario">Conteúdo</label>
                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={setEditorContent} // Atualiza o conteúdo do editor
                        style={{ height: '270px' }} // Define a altura do editor
                    />

                </Col>

            </Row>
            <br />
            <br />
            {
                enviandoSms ? 
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarSms}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </>
    );
}
export default EnviarMensagemSmsForm;
 