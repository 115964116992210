import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { H5, H6 } from '../../../../../AbstractElements';
import { PlusCircle } from 'react-feather';
import axios from 'axios';
import ReactQuill from 'react-quill';
import clienteService from '../../../../../Services/administrativo/cliente.service';
import SweetAlert from 'sweetalert2';

const EnviarMensagemEmailForm = (props) => {
    const company = JSON.parse(localStorage.getItem('company'));
    const [editorContent, setEditorContent] = useState('');
    const [dadosUsuario, setDadosUsuario] = useState({});
    const [assunto, setAssunto] = useState(''); // Adiciona o estado para o destinatário do e-mail 

    const [loading, setLoading] = useState(true);
    const [enviandoEmail, setEnviandoEmail] = useState(false);

    
    useEffect(() => {
        setLoading(true);
        getDadosCliente();
    }, []);

    const getDadosCliente = () => {
        return clienteService.findById(props.cliente)
            .then((response) => {
                setDadosUsuario(response.data);
                setLoading(false);
                return response;
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };



    // Função para enviar o e-mail
    const enviarEmail = async () => {
        setEnviandoEmail(true);
        try {

            const response = await clienteService.enviarEmail(company.id, {
                subject: assunto,
                body: editorContent,
                companyId: company.id,
                userId: dadosUsuario.client.user.id,
                companyId: company.id
            });
            setEnviandoEmail(false);

            if (response.status === 201) {
                SweetAlert.fire({
                    title: 'E-mail enviado com sucesso!',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                }).then((result) => {
                    setAssunto('');
                    setEditorContent('');
                });
            } else {
                SweetAlert.fire({
                    title: 'Erro ao enviar email',
                    icon: 'danger',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true,
                });

            }
        } catch (error) {
            console.error('Erro ao enviar e-mail:', error);
            alert('Erro ao enviar e-mail.');

            setEnviandoEmail(false);

        }
    };

    return (loading ? 'Carregando dados' :
        <>
            Enviar email para: <H5>{dadosUsuario.client.mail}</H5>

            <Row>
                <Col>
                    <FormGroup>
                        <Label className="col-form-label">Assunto:</Label>

                        <Input className="form-control btn-square " type="text" onChange={(e) => setAssunto(e.target.value)} value={assunto} />
                    </FormGroup>
                </Col>

            </Row>
            <Row>
                <Col>
                    <label htmlFor="emailDestinatario">Conteúdo</label>
                    <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={setEditorContent} // Atualiza o conteúdo do editor
                        style={{ height: '220px' }} // Define a altura do editor
                    />

                </Col>

            </Row>
            <br />
            <br />
            {
                enviandoEmail ? 
                    <button type="button" className="btn btn-success disabled w-100 btn-sm input-group-text" >
                        <H6><PlusCircle /> Enviando... </H6>
                    </button> :
                    <button type="button" className="btn btn-success w-100 btn-sm input-group-text" onClick={enviarEmail}>
                        <H6><PlusCircle /> Enviar </H6>
                    </button>
            }

        </>
    );
}
export default EnviarMensagemEmailForm;
