import React, { Fragment , useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import MyProfileEdit from './MyProfile';
import EditMyProfile from './EditMyProfile';
import userService from '../../../../Services/administrativo/user.service'; 
 
const UsersEditContain = () => {
  const userId = JSON.parse(localStorage.getItem('userId')); 
  const [ userEdit, setUserEdit ]  = useState({});
  const [loading, setLoading] = useState(true);

  console.log(userId);
 
  useEffect(() => {
   
    fetUser();
  }, []); 

  const fetUser = async () => {
    try {  
      const response = await userService.getId(userId);        
      const data = await response.data;
      setUserEdit(data);
      setLoading(false);
    } catch (error) {
      console.error('Error to load options:', error);
      setLoading(false);
    } 
  };

  return ( loading ? '' : 
    <Fragment>
      <Breadcrumbs mainTitle='Edit Profile' parent='Users' title='Edit Profile' />
      <Container fluid={true}>
        { loading ? 'Carregando' : 
        <div className='edit-profile'>
          <Row>
            <Col xl='4'>
             <MyProfileEdit user = {userEdit} /> 
            </Col>
            <Col xl='8'>
                <EditMyProfile person = {userEdit.person} />   
            </Col>
            
          </Row>
        </div> }
      </Container>
    </Fragment>
  );
};
export default UsersEditContain;
