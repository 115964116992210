// Arquivo gerado de forma automática pelo gerador. 
import React, { Fragment , useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Btn} from '../../../../AbstractElements';
import { Form, Input, Col, Container, Row } from 'reactstrap';
import companyService from '../../../../Services/administrativo/company.service'; 
import { toast } from "react-toastify";
import { New, TextSearch, Edit, List} from "../../../../Constant"; 

import SweetAlert from 'sweetalert2';
import CompanyListData from './company-list';
import CompanyFormData from './company-form';
import { Delete, Edit2, Link, Plus, Search, Trash } from 'react-feather';
import CommonModal from '../../../../CommonElements/Modals/common/modal';
import CompanyIntegrationForm from './company-integration-form';

const CompanyPage = () => {
  const { t } = useTranslation();
  const [contries, setContries] = useState([]); 
  const [option, setOption] = useState(""); 
  const [filtroName, setFiltroName] = useState(null); 
  const [person, setPerson] = useState({}); 
  const [company, setCompany] = useState({}); 
  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0); 
  const [totalRows, setTotalRows] = useState(0); 
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const [open, setOpen] = useState(false);
  const [optionIntegration, setOptionIntegration] = useState("");
  const toggle = (user) => {
    setCompany(company);

    if (open) setOpen(false);
    else {
      setOptionIntegration('');
      setOpen(true);
    }
    setOpen(!open); 
  }

  var requestFilter = {  
    name : filtroName
  } 

  useEffect( () => {
    setOptionIntegration('');
  }, []);
 
  const btnNovaIntegracao = () => {
    setOptionIntegration('NewIntegration');

  }

  const getListAll = async (e) => {
    setLoading(true);
    try {
      	const response = await companyService.getFilter(requestFilter , page, size);    
      	var companyList = await response.data.content;
      	setTotalRows(response.data.totalItens);
      	const transformedItems = companyList.map((company) => ({
	      	...company,
          integrations: (
            <div>
              <span >
                <Btn attrBtn={{ style: style2, color: 'info', className: 'btn btn-xs', type: 'button', onClick: (e) =>  toggle(company)  }}>
                  <Link style={{ width: '18px', height: '18px' }} className='me-2' />
                  </Btn>
              </span>
            </div>
          ),
	      	action : (
		        <div>
		          <span>
		            <Btn attrBtn={{  color: 'danger', type: 'button', onClick : (e) => btnDelete(company) }}>
                  <Trash style={{ width: '18px', height: '18px' }} className='me-2' />  </Btn>
		          </span>{' '}
		          &nbsp;&nbsp;
		          <span>
		            <Btn attrBtn={{  color: 'warning',  type: 'button' , onClick : (e) => btnEdit(company) }}>
                <Edit2 style={{ width: '18px', height: '18px' }} className='me-2' /></Btn>
		          </span>
		        </div>
	      	)
    		}
      	)
      );

      setContries(transformedItems);    
      setLoading(false);
    } catch (error ) {
      setError(error);
      setLoading(false);
    }
    return companyList;
  }
   
  const btnEdit = (person) => {
    setOption(Edit); 
    setPerson(person); 
  }

  const btnDelete = (person) => {
    setOption(Delete); 
    setPerson(person); 
  }

  const deleteperson = (request) => {
    if (request.id != null && request.id >0) {
      SweetAlert.fire({
        title: 'Are you right?',
        text: 'Uma vez excluído, você não poderá recuperar este registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          companyService.delete(request).then((response) => {
            var removeu = response.data;
            if (removeu) {
              SweetAlert.fire(
                'Removed!',
                'Registro foi apagado com sucesso.',
                'success'
              ); 
              getListAll();
              setOption(List);
              setPerson({});
              return response;
            } else {
              SweetAlert.fire(
                'Erro!',
                'Erro ao remover registro.',
                'warning'
              ); 
             
              return response;
            }


            
          });
  
        } else {
          SweetAlert.fire(
            'The register went deleted with security!'
          );
        }
      });


    }  
  }

  const btnNovo = () => {
    initialize();
    setOption(New);
    initialize();
  }
  
  const initialize = () => {
    setError(null);
		setPerson( {	id : -1 , 	name : "" , 	corporateName : "" , 	nickname : "" , 	rg : "" , 	phone : "" , 	cellphone : "" , 	mail : "" , 	zipCode : "" , 	address : "" , 	number : "" , 	complement : "" , 	district : "" , 	cpfCnpj : "" , } );
  }

  const btnCancel = () => {
    initialize();
    setOption(List);
  }


  const save = (request) => {

    console.log(request);

    return companyService.save(request)
    .then((response) => {
      initialize();
      setOption(List);
      toast.success("Empresa cadastrada com sucesso!"); 
      getListAll();
      return response;
    }).catch((error) => {
      setErrors(error.fieldErrors);
    });
     
  } 

  const update = async  (request) => {
    setLoading(true);
     if (request.id != null) {
       return companyService.update(request)
       .then((response) => {
         toast.success("Empresa alterada com sucesso!"); 
         getListAll();
         setOption(List);
         initialize();
         setLoading(false);
         return response;
       }).catch((error) => {
         toast.error("Erro ao logar = "+ error.response.data);
         setError(error);
         setLoading(false);
       });
     }  
   } 
   const handlePageChange = (page) => {
    setPage(page); 
    getListAll();
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setPage(0);
    setSize(newPerPage);
    getListAll();
  };
  

  return (
    <Fragment>
      <Breadcrumbs mainTitle='Gestão de empresas' parent='Home' title='empresas' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
              
                <Form className='theme-form'>

                  <div className='input-group flex-nowrap'>
                      <span className='btn btn-success input-group-text' onClick={() => btnNovo() }>
                        <Plus/>
                        {t(New)}</span> 

                      <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)}
                      onChange={(e) =>setFiltroName(e.target.value)}  value={filtroName} />
                      <span className='btn btn-primary input-group-text'  onClick={() => {
                                  setOption(List);
                                  setPage(0);
                                  getListAll(requestFilter);
                                } }><Search/> {t(TextSearch)}</span>
                  </div>
                </Form>
                <br />

               
                { 
                option === New || option === Edit  || option === Delete
                ?  
                <CompanyFormData errors = {errors} loading={loading} error={error}  cancel={btnCancel} option={option}  delete={deleteperson}  update={update} save={save} data = {person} />
                : option === List ? 
                <CompanyListData totalRows = {totalRows} loading={loading} error={error}  handlePerRowsChange={handlePerRowsChange} handlePageChange={handlePageChange} list={contries} /> : '' }
          </Col>
        </Row>
      </Container>
      <CommonModal isOpen={open} title={'Integrações'} toggler={toggle} size="lg">
        <Form className='theme-form'>
          <div className='input-group flex-nowrap'>
            <span className='btn btn-success input-group-text'onClick={() => btnNovaIntegracao()}>{t(New)}</span>
            <Input className='form-control-plaintext' type='search' placeholder={t(TextSearch)} />
            <span className='btn btn-primary input-group-text' onClick={() => {}}>{t(TextSearch)}</span> 
          </div>
        </Form>
        <br/>
        <CompanyIntegrationForm company={company} option={optionIntegration}  toggle = {toggle}  />  
      </CommonModal>
    </Fragment>
  );
};

export default CompanyPage;
