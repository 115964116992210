import { Row, Col } from "react-bootstrap";
import VisitorsCard from "./VisitorsCard";
import UserService from "../../../../Services/administrativo/user.service";
import RecentSales from "./RecentSales";

const LeftGrid = () => {
    var hasRoleVisitants = UserService.isHasRole('can_view_crm_dashboard_visitants');

    return (<Col xxl='12' md='12' className='box-col-8 grid-ed-12'>
        <Row className='row'>
            <Col xxl='5' xl='5' md='7' className='box-col-7'>
                <Row>
                {hasRoleVisitants ? <VisitorsCard /> : ''}
                </Row>
            </Col>
            <Col xxl='7' xl='7' sm='6' className='box-col-6'>
                <RecentSales/>
            </Col>
          
        </Row>
    </Col>);
};

export default LeftGrid;