import React, { Fragment, useState } from "react";
import { Breadcrumbs, Btn, H5, } from "../../../../../AbstractElements";
import { Card, CardBody, CardFooter, Col, Container, Form, FormGroup, Input, Label, Row, } from "reactstrap";
import companyService from "../../../../../Services/administrativo/company.service";
import { toast } from "react-toastify";

const CrmMinhaLojaParametrizacao = () => {
  var companyLocal = JSON.parse(localStorage.getItem('company'));

  const [valorParaPontuar, setValorParaPontuar] = useState(companyLocal.valorParaPontuar ? companyLocal.valorParaPontuar : 0);  
  var request = {
    valorParaPontuar : valorParaPontuar
  }

  const update = () => { 
     
      return companyService.atualizarValorParaPontuar(companyLocal.id, request)
        .then((response) => {
          toast.success("Informações básicas alterada com sucesso!");
          companyLocal.valorParaPontuar = valorParaPontuar;
          localStorage.setItem('company', JSON.stringify(companyLocal));
          return response;
        });
    
  }

  return (
   <> <Fragment>
   <Breadcrumbs mainTitle='Parametrizações' parent='CRM' title='Minha loja' />
   <Container fluid={true}>
     <div className='user-profile social-app-profile'>
       <Row>
         <Col>
           <Card>
             <CardBody>
               <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">
                 <fieldset>
                   <legend><H5>Regras de pontuação</H5></legend>

                   <FormGroup>
                     <Label className="col-form-label"> Quanto o usuário deve compara para ganhar uam ponto?</Label>
                     <Input className="form-control btn-square " type="text" onChange={(e) => setValorParaPontuar(e.target.value)} value={valorParaPontuar} />
                   </FormGroup>
                 </fieldset></Form>
             </CardBody>
             <CardFooter className="text-end">
               <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit",  onClick: (e) => update() }}   >{'Salvar'}</Btn>
             </CardFooter>
           </Card>
         </Col>
       </Row>
     </div>
   </Container>
 </Fragment></>
  );
};

export default CrmMinhaLojaParametrizacao; 