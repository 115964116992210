import React , {useContext} from 'react';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Callback from '../Auth/Callback';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes'; 
import PrivateRoute from './PrivateRoute';
import { authRoutes } from './AuthRoutes';  
import Error404 from '../Components/Pages/ErrorPages/ErrorPage404'; 
import AuthContext from '../_helper/Auth';
import LoginTab from '../Auth';


const Routers = () => {
  const {  authenticated} = useContext(AuthContext); 
  const jwt_token = localStorage.getItem('token');
  const [accessToken, setAccessToken] = useState(jwt_token ?? null);  
 
  useEffect(() => {
    let abortController = new AbortController();

    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (


    <BrowserRouter basename='/' >

      <Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<LoginTab />} />
          <Route exact path={'/'} element={<PrivateRoute />}>
            {authenticated && accessToken ? (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />} />
              </>
            ) : (
              <>
                <Route exact path={`${process.env.PUBLIC_URL}/lock`} element={<Navigate to={`${process.env.PUBLIC_URL}/lock`} />} />
              </> 
            )}
            <Route path={`/*`} element={<LayoutRoutes />} />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} /> 
          {authRoutes.map(({ path, Component }, i) => (
            <Route path={path} element={Component} key={i} />
          ))}
          <Route path='/*' element={<Error404 />} /> {/* Rota de fallback para a página 404 */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
