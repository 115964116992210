import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Badges, Breadcrumbs, H5 } from "../../../../../AbstractElements";
import AllClientes from "./AllClientes";
import clientService from "../../../../../Services/administrativo/cliente.service"; 
import { useTranslation } from "react-i18next";
import { Download, Search, Upload } from "react-feather";
import 'react-date-range/dist/styles.css'; // Estilos principais
import 'react-date-range/dist/theme/default.css'; // Tema padrão
import ImportarClienteModal from "../importacaocliente";
import ExportarClienteModal from "../exportacaoCliente";
import EnviarMensagemCliente from "../enviarmensagem";
import segmentacaoService from "../../../../../Services/crm/segmentacao.service";
import DateService from "../../../../../Services/utils/date.service";
import SkeletonTable from "../../../../../Layout/SkeletonTable"; 

const CrmClienteListaClientes = () => {
  const company = JSON.parse(localStorage.getItem('company'));
  const [clients, setClients] = useState([]);
  const [cliente, setCliente] = useState({});
  const [segmentacoes, setSegmentacoes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtroName, setFiltroName] = useState("");
  const { t } = useTranslation();
  const [classificacao, setClassificacao] = useState("");
  const [segmentacaoId, setSegmentacaoId] = useState(null);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [ultimaPagina, setUltimaPagina] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
   

  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [modalExportar, setModalExportar] = useState(false);
  const [modalImportar, setModalImportar] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const [modalSms, setModalSms] = useState(false);

  const toggleWhatsApp = useCallback(() => {
    setModalWhatsApp(!modalWhatsApp);
  }, [modalWhatsApp]);

  const toggleSms = useCallback(() => {
    setModalSms(!modalSms);
  }, [modalSms]);

  const toggleMail = useCallback(() => {
    setModalMail(!modalMail);
  }, [modalMail]);

  const toggleExportar = useCallback(() => {
    setModalExportar(!modalExportar);
  }, [modalExportar]);

  const toggleImportar = useCallback(() => {
    setModalImportar(!modalImportar);
  }, [modalImportar]);

  const requestFilter = {
    name: filtroName,
    classificacao: classificacao === 'Todas' ? null : classificacao,
    segmentacaoId: segmentacaoId === 'Selecione' ? null : segmentacaoId,
  };

  const updateCliente = (cliente) => {
    setCliente(cliente);
  };
 

  const getListAll = async (page = 1, perPage = 10) => {
    setLoading(true);
    try {
      const response = await clientService.novaListaAll(company.id, requestFilter, page - 1, perPage, sortColumn, sortDirection);
      const clientesList = response.data.content;
      setTotalRows(response.data.totalElements);
      setUltimaPagina(response.data.last);
      setCurrentPage(page)

      const transformedItems = clientesList.map((cliente) => ({
        ...cliente,
        action: (
          <div className='d-flex align-items-center'>
            <button className='btn btn-info' onClick={() => { toggleWhatsApp(); updateCliente(cliente); }}>
              <i className='fa fa-wechat'></i>
            </button>
          </div>
        ),
        initialDate: (
          <div>
            {DateService.getDate(cliente.initialDate)} <br /> <i>{clientService.getFormaCadastro(cliente.formaCadastro)}</i>
          </div>
        ),
        status: (
          <Badges attrBadge={{ className: 'badge rounded-pill', color: cliente.status === 'ACTIVE' ? 'success' : 'dark', pill: true }}>
            {cliente.status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
          </Badges>
        ),
        name: (
          <>
            {cliente.name} {DateService.convertBadge(cliente.classificacoes ? cliente.classificacoes : null)}
          </>
        ),
      }));

      setClients(transformedItems);
    } catch (error) {
      console.error("Erro ao carregar clientes:", error);
    } finally {
       setLoading(false);
    }
  };

  useEffect(() => {
   // window.scrollTo(0, 0);
    getListAll(page, perPage);
    getSegmentacoes();
  }, [page, perPage]);

  const getSegmentacoes = async () => {
    try {
      const response = await segmentacaoService.findByCompanyId(company.id, {});
      setSegmentacoes(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <>
      <Breadcrumbs mainTitle={company.name} parent='CRM' title='Dashboard' />
      <Container fluid={true}>
        <Card>
          <CardHeader className='card-no-border'>
            <div className='header-top'>
              <H5>Lista de Clientes</H5>
            </div>
          </CardHeader>
          <CardBody className='pt-0'>
            <Form className='theme-form'>
              <Row>
                <Col md={7}>
                  <FormGroup>
                    <Label className="col-form-label">Buscar pelo nome:</Label>
                    <Input
                      className="form-control border border-black-100"
                      type='search'
                      placeholder={t('Search')}
                      onChange={(e) => setFiltroName(e.target.value)}
                      value={filtroName}
                    />
                  </FormGroup>
                </Col>
                <Col md={5}>
                  <FormGroup>
                    <Label className="col-form-label">Classificação:</Label>
                    <div className="position-relative">
                      <Input type="select" value={classificacao}
                        onChange={(e) => setClassificacao(e.target.value)}>
                        <option >{'Todas'}</option>
                        <option value={"NOVO"} >{'Novo'}</option>
                        <option value={"VIP"} >{'Vip (Pendente)'}</option>
                        <option value={"HABITUAIS"} >{'Habituais (Pendente)'}</option>
                        <option value={"ESPORATICOS"} >{'Esporáticos (Pendente)'}</option>
                        <option value={"UMA_COMPRA"} >{'Uma compra'}</option>
                        <option value={"PERDIDOS"} >{'Perdidos'}</option>
                        <option value={"SEM_COMPRAS"} >{'Sem compras'}</option>
                        <option value={"REGULARES"} >{'Regulares (Pendente)'}</option>
                        <option value={"RECUPERADOS"} >{'Recuperados (Pendente)'}</option>
                      </Input>       <span
                        className="fa fa-chevron-down position-absolute"
                        style={{ top: '50%', right: '15px', transform: 'translateY(-50%)', pointerEvents: 'none' }}
                      ></span>
                    </div>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={7}>
                  <FormGroup>
                    <Label className="col-form-label">Segmentação:</Label>
                    <Input type="select" value={segmentacaoId} onChange={(e) => setSegmentacaoId(e.target.value)}>
                      <option>{'Selecione'}</option>
                      {segmentacoes.map((segmentacao) => (
                        <option key={segmentacao.id} value={segmentacao.id}>
                          {segmentacao.descricao} - ({segmentacao.total} Clientes)
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col>
                  <Col>
                    <div className='d-flex align-items-center gap-3 w-100 mt-4'>

                      <button  type="button" className='btn btn-sm btn-success input-group-text flex-grow-1' onClick={() => getListAll()}>
                        <Search /> {t('Search')}
                      </button>
                      <button  type="button" className='btn btn-sm  btn-info btn-sm input-group-text flex-grow-1' onClick={toggleImportar}>
                        <Download /> {t('Importar')}
                      </button>
                      <button type="button" className='btn btn-sm  btn-warning btn-sm input-group-text flex-grow-1' onClick={toggleExportar}>
                        <Upload /> {t('Exportar')}
                      </button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Form>
            <hr />

            {loading ? <div>
              <SkeletonTable rows={10} columns={8} />
        </div> : (
              <AllClientes
                totalRows={totalRows}
                perPage={perPage}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                clients={clients}
                toggleWhatsApp={toggleWhatsApp}
                toggleMail={toggleMail}
                toggleSms={toggleSms}
                ultimaPagina={ultimaPagina}
                currentPage = {currentPage}
               
              />
            )}
          </CardBody>
        </Card>
      </Container>

      <EnviarMensagemCliente cliente={cliente} modal={modalWhatsApp} toggle={toggleWhatsApp} />
      <ImportarClienteModal modal={modalImportar} toggle={toggleImportar} />
      <ExportarClienteModal modal={modalExportar} toggle={toggleExportar} requestFilter = {requestFilter} />
    </>
  );
};

export default CrmClienteListaClientes;
