import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Row, Col, Form, FormGroup, Label, Input, CardHeader, CardBody, Card } from 'reactstrap';
import { Alerts, Btn, H4, H5 } from '../../../../AbstractElements';
import { CardFooter } from 'reactstrap'
import { Submit, Cancel, Delete, Edit, New, Select } from "../../../../Constant";
import personTypeService from '../../../../Services/administrativo/personType.service';
import stateService from '../../../../Services/administrativo/state.service';
import cityService from '../../../../Services/administrativo/city.service';
import dateService from '../../../../Services/utils/date.service';
import cepService from '../../../../Services/utils/cep.service';
import MaskedInput from 'react-text-mask';
import { Edit2, Edit3, Image, List } from 'react-feather';

import { EditProfile, UpdateProfile } from '../../../../Constant';


// Máscara para telefone
const PhoneMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
        {...otherProps}
        inputRef={inputRef} // Correção: passando a referência diretamente
        mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
    />
);

// Máscara para telefone
const CellPhoneMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
        {...otherProps}
        inputRef={inputRef} // Correção: passando a referência diretamente
        mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
    />
);

// Máscara para CEP
const CepMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
        {...otherProps}
        inputRef={inputRef} // Correção: passando a referência diretamente
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
    />
);



// Máscara para CEP
const CpfMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
        {...otherProps}
        inputRef={inputRef} // Correção: passando a referência diretamente
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
    />
);



// Máscara para CEP
const CnpjMask = ({ inputRef, ...otherProps }) => (
    <MaskedInput
        {...otherProps}
        inputRef={inputRef} // Correção: passando a referência diretamente
        mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
    />
);




const EditMyProfile = ({ person }) => {
    const [personType, setPersonType] = useState(person.personType ? person.personType : 'FISICA');
    const [id, setId] = useState(person.id);
    const [corporateName, setCorporateName] = useState(person.corporateName);
    const [nickname, setNickname] = useState(person.nickname);
    const [cpfCnpj, setCpfCnpj] = useState(person.cpfCnpj);
    const [fantasyName, setFantasyName] = useState(person.id);
    const [rg, setRg] = useState(person.rg);
    const [name, setName] = useState(person.name);
    const [dateOfBirth, setDateOfBirth] = useState(dateService.getDate(person.dateOfBirth));
    const [phone, setPhone] = useState(person.phone);
    const [cellphone, setCellphone] = useState(person.cellphone);
    const [mail, setMail] = useState(person.mail);
    const [zipCode, setZipCode] = useState(person.zipCode);
    const [address, setAddress] = useState(person.address);
    const [number, setNumber] = useState(person.number);
    const [imageUrl, setImageUrl] = useState('');
    const [complement, setComplement] = useState(person.complement);
    const [district, setDistrict] = useState(person.district);
    const [stateId, setStateId] = useState(person.city ? person.city.state.id : null);
    const [cityId, setCityId] = useState(person.city ? person.city.id : null);
    const [listOfState, setListOfState] = useState([]);
    const [listOfCity, setListOfCity] = useState([]);
    const [validCep, setValidCep] = useState(true);
    const [state, setState] = useState({});
    const fileInputRef = useRef(null);
    const somenteLeitura = false;
    const option = '';

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (event.target.files.length === 0) return;

        try {
            const formData = new FormData();
            formData.append('file', event.target.files[0]);

        } catch (error) {
            console.error('Erro ao enviar o arquivo:', error);
        }
    };

    const updateCityList = async (uf, codeIbge) => {
        try {
            if (uf === '') {
                setListOfCity([]);
            } else {
                if (uf == null) setListOfCity([]);
                else {
                    const response = await cityService.getAllByUf(uf);
                    const data = await response.data;
                    setListOfCity(data);
                    setCityId(data.find(city => city.code === codeIbge).id);
                    person.cityId = data.data.find(city => city.code === codeIbge).id;
                    person.city = data.data.find(city => city.code === codeIbge).id;
                }
            }
        } catch (error) {
            console.error('Error to load options:', error);
        }
    }



 




    const updateCep = async (cep) => {
        try {

            cep = cep.trim();
            if (cep === null || cep === '' || cep.replace('.', '').replace('-', '').length !== 8) {
                return null;
            }

            await cepService.getCep(cep).then((response) => {
                const data = response.data;
                if (data.erro) {
                    setValidCep(false);
                    setAddress('');
                    setDistrict('');
                    setNumber('');
                    setComplement('');
                    setStateId(null);
                    setCityId(null);
                    setListOfCity([]);
                    person.address = address;
                    person.district = district;
                    person.number = number;
                    person.complement = complement;
                    person.stateId = stateId;
                    person.cityId = cityId;
                    person.city = cityId;


                } else {
                    setValidCep(true);
                    var newStateId = listOfState.find(state => state.acronym === data.uf).id;
                    if (newStateId === stateId) {
                        setCityId(listOfCity.find(city => city.code === data.ibge).id);
                    } else {
                        setStateId(newStateId);
                        updateCityList(data.uf, data.ibge);
                    }


                    setAddress(data.logradouro);
                    setDistrict(data.bairro);
                    setNumber('');
                    setComplement('');

                    person.address = data.logradouro;
                    person.district = data.bairro;
                    person.number = number;
                    person.complement = complement;

                    person.zipCode = cep;

                }


            }

            );



        } catch (error) {
            console.error('Error to load options:', error);
        }
    }

    useEffect(() => {
        const fetListOfState = async () => {
            try {
                const response = await stateService.getAll();
                const data = await response.data;
                setListOfState(data);
            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetListOfState();
    }, []);


    useEffect(() => {
        const fetListOfCity = async () => {
            try {
                if (stateId == null) {
                    setListOfCity([]);
                } else {
                    const response = await cityService.getAll(stateId);
                    const data = await response.data;
                    setListOfCity(data);
                }
            } catch (error) {
                console.error('Error to load options:', error);
            }
        };
        fetListOfCity();
    }, []);




    return (
        <Card className={'card-absolute'}>
            <CardHeader className={'bg-primary'}>
                <H5>Informações Pessoais</H5>
            </CardHeader>
            <CardBody>
                <Form className="theme-form mega-form" id="formCountry" name="formCountry" method="POST">


                   



                    <Row>

                        <Col md={12}>
                            

                                <Row>


<Col md={3}>
    <FormGroup>
        <Label className="col-form-label">{personType === 'FISICA' ? 'CPF' : 'CNPJ'}</Label>
        <Input className="form-control disabled " style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}} tag={person.personType === 'FISICA' ? CpfMask : CnpjMask} type="text" placeholder={personType === 'FISICA' ? '000.000.000-00' : '00.000.000/0000-00'} onChange={(e) => setCpfCnpj(e.target.value)} value={cpfCnpj} />
    </FormGroup>
</Col>

</Row>


                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="col-form-label"> Name</Label>
                                            <Input className={'form-control'}
                                                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                                disabled={somenteLeitura} type="text"
                                                onChange={(e) => { setName(e.target.value); person.name = name }} value={name} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={3}><FormGroup>
                                        <Label className="col-form-label">Rg</Label>
                                        <Input className={'form-control '}
                                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                            readOnly={somenteLeitura} type="text" onChange={(e) => { setRg(e.target.value); person.rg = rg; }} value={rg} />
                                    </FormGroup>
                                    </Col>
                                    <Col md={3}><FormGroup>
                                        <Label className="col-form-label">Date of Birth</Label>
                                        <Input className="form-control"
                                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                            type="date" readOnly={somenteLeitura} placeholder="dd/mm/aaaa" onChange={(e) => { setDateOfBirth(e.target.value); person.dateOfBirth = dateOfBirth; }} value={dateOfBirth} />
                                    </FormGroup>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>


                    <H5>Address</H5>
                    <Row><Col md={3}><FormGroup>
                        <Label className="col-form-label">ZipCode</Label>
                        <Input className={'form-control'}
                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                            tag={CepMask} readOnly={somenteLeitura} type="text" onChange={(e) => { setZipCode(e.target.value); person.zipCode = zipCode; updateCep(e.target.value); }} value={zipCode} />
                    </FormGroup>
                    </Col><Col md={6}><FormGroup>
                        <Label className="col-form-label">Address</Label>
                        <Input className={'form-control '}
                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                            readOnly={somenteLeitura} type="text" onChange={(e) => { setAddress(e.target.value); person.address = address; }} value={address} />
                    </FormGroup>
                        </Col><Col md={3}><FormGroup>
                            <Label className="col-form-label">Number</Label>
                            <Input className={'form-control '}
                                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                type="text" readOnly={somenteLeitura} onChange={(e) => { setNumber(e.target.value); person.number = number; }} value={number} />
                        </FormGroup>
                        </Col></Row><Row><Col md={3}><FormGroup>
                            <Label className="col-form-label">Complement</Label>
                            <Input className={'form-control '}
                                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                type="text" readOnly={somenteLeitura} onChange={(e) => { setComplement(e.target.value); person.complement = complement; }} value={complement} />
                        </FormGroup>
                        </Col>
                        <Col md={3}><FormGroup>
                            <Label className="col-form-label">District</Label>
                            <Input className={'form-control '}
                                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                type="text" readOnly={validCep || somenteLeitura} onChange={(e) => { setDistrict(e.target.value); person.district = district; }} value={district} />
                        </FormGroup>
                        </Col><Col md={3}>

                            <FormGroup>
                                <Label className="col-form-label">State:</Label>
                                <select className='form-control'
                                    style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                    size='1' value={stateId} onChange={(e) => { setStateId(e.target.value.id); updateCityList(e.target.value); person.stateId = stateId; }}>
                                    <option value='' >{Select}</option>
                                    {listOfState.map((state) => (
                                        <option value={state.id} key={state.id} selected={state.id === stateId} >{state.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col><Col md={3}>
                            <FormGroup>
                                <Label className="col-form-label">City:</Label>
                                <select className='form-control'
                                    style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                    size='1' value={cityId} onChange={(e) => { setCityId(e.target.value); person.stateId = stateId; }}>
                                    <option >{Select}</option>
                                    {listOfCity.map((city) => (
                                        <option value={city.id} key={city.id} selected={city.id === city} >{city.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    <H5>Contatos</H5>
                    <Row><Col md={3}><FormGroup>
                        <Label className="col-form-label">Phone</Label>
                        <Input tag={PhoneMask}
                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                            className={'form-control '} type="text" placeholder="Phone" onChange={(e) => { setPhone(e.target.value); person.phone = phone; }} value={phone} />
                    </FormGroup>
                    </Col><Col md={3}><FormGroup>
                        <Label className="col-form-label">Cellphone</Label>
                        <Input tag={CellPhoneMask}
                            style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                            className={'form-control '} type="text" placeholder="Cellphone" onChange={(e) => { setCellphone(e.target.value); person.cellphone = cellphone; }} value={cellphone} />
                    </FormGroup>
                        </Col><Col md={6}><FormGroup>
                            <Label className="col-form-label">Mail</Label>
                            <Input className={'form-control '}
                                style={somenteLeitura ? { 'background-color': '#f2f2f2' } : {}}
                                type="text" placeholder="Mail" onChange={(e) => { setMail(e.target.value); person.mail = mail; }} value={mail} />
                        </FormGroup>
                        </Col>
                    </Row>
                </Form></CardBody>
                <CardFooter className="text-end">
                <Btn attrBtn={{
                color: "success", className: "m-r-15", type: "submit" } } >{'Salvar'}</Btn>
                </CardFooter>

        </Card>
    );
};
export default EditMyProfile;
