import React from 'react';
import Routers from './Route';
import ProductProvider from './_helper/Ecommerce/Product/ProductProvider';
import CartProvider from './_helper/Ecommerce/Cart/CardProvider';
import FilterProvider from './_helper/Ecommerce/Filter/FilterProvider';
import WishListProvider from './_helper/Ecommerce/Wishlist/WishlistProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
import RecaptchaProvider from './_helper/Recaptcha/RecaptchaProvider';
import FaqProvider from './_helper/Faq/FaqProvider';
import EmailProvider from './_helper/Faq/FaqProvider';
import { AuthProvider } from './_helper/Auth/AuthProvider';

const App = () => (
  <div className='App'>
    <AuthProvider>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
    </AuthProvider>
  </div>
);

export default App;
