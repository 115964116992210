import React, { Fragment} from "react";
import { Breadcrumbs } from "../../../../AbstractElements";
import { Container, Row } from "reactstrap"; 
import LeftGrid from './LeftGrid';   

const CrmDashBoardDefault = () => { 
  const company = JSON.parse(localStorage.getItem('company'));
  return (
    <Fragment>
      <Breadcrumbs mainTitle={company.name} parent='CRM' title='Dashboard' />
      <Container fluid={true}>
        <Row className='size-column'>         
          <LeftGrid />           
        </Row>
      </Container> 
    </Fragment>
  );
};

export default CrmDashBoardDefault; 