import axios from 'axios';
import authHeader from '../auth-header';
import config from '../../Config/AppConfig';  
import authHeaderFile from "../auth-header-file";

const API_URL = config.data.API_URL+"/authentication/user"; 

const  UserService =  {
  
  getFilter(request, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter?page='+page+'&size='+size, request, { headers: authHeader() });
  },
  
  getAll(filter, page, size, sort) {    
    return axios
      .post(API_URL+ '/filter', filter, { headers: authHeader() });
  },

  getId(userId) {    
    return axios
      .get(API_URL+ '/'+userId,  { headers: authHeader() });
  },

  uploadSigleFile(request) {    
    return axios
      .post(API_URL + '/upload/single-file', request, { headers: authHeaderFile() });
  },
   
  updateTheme(userId, newTheme) {
    return axios.put(API_URL + '/updateTheme/'+userId+'?newTheme='+newTheme, {}, { headers: authHeader() });
  } ,
  updateLigthOn(userId, lightOn) {
    return axios.put(API_URL + '/updateLigthOn/'+userId+'/'+lightOn, {}, { headers: authHeader() });
  } ,
  register(request) {
    return axios.post(API_URL + '/register', request, { headers: authHeader() });
  } ,

  updateLanguage(userId, Language) {
    return axios.put(API_URL + '/updateLanguage/'+userId+'/'+Language,  {},  { headers: authHeader() });
  } ,

  uploadPhoto(userId, request ) {    
    return axios
      .post(API_URL + '/uploadPhoto/'+userId, request, { headers: authHeaderFile() });
  },
  save(request) {    
    return axios
      .post(API_URL, request, { headers: authHeader() });
  } ,

  uploadPerfis(userId, perfilId) {    
    return axios
      .put(API_URL + '/updatePerfis/'+userId + "/"+perfilId, {}, { headers: authHeader() });
  },

  updateModuloAtual(userId, module) {    
    return axios
      .put(API_URL + '/updateModuloAtual/'+userId + "/"+module, {}, { headers: authHeader() });
  }
,

  isHasRole(role) {    
    const roles = JSON.parse(localStorage.getItem('roles'));
    return roles.includes(role);   
  }
}

 
export default UserService;    