import axios from 'axios';
import authHeader from '../auth-header';
import config from '../../Config/AppConfig';

const API_URL = config.data.API_URL + "/authentication/userPerfil";

const UserPerfilService = {

  insert(request) {
    return axios
      .post(API_URL, request, { headers: authHeader() });
  },

  getAll(userId) {
    return axios
      .get(API_URL + '/' + userId, { headers: authHeader() });
  },

  getCompanies(userId) {
    return axios
      .get(API_URL + '/companies/' + userId, { headers: authHeader() });
  },

  updateCompany(userId, companyId) {
    return axios
      .put(API_URL + '/updateCompany/' + userId + '/' + companyId,  {}, { headers: authHeader() });
  },

}


export default UserPerfilService;    